// reducers.js
const initialState = {
    loading: false,
    notification_count_arr: [],
    notification_arr: [],
    total_count: 0,
    total_unread: 0,
    acceptPolicyOpen: false,
    // Add other initial state properties as needed
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_HOME_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_NOTIFICATION_COUNT':
            return { ...state, notification_count_arr: action.payload };
        case 'SET_NOTIFICATION':
            return { ...state, notification_arr: action.payload };
        case 'SET_NOTIFICATION_COUNTER':
            return { ...state, total_count: action.payload };
        case 'SET_NOTIFICATION_UNREAD_COUNT':
            return { ...state, total_unread: action.payload };
        case 'SET_ACCEPT_POLICY_MODAL_OPEN':
            return { ...state, acceptPolicyOpen: action.payload };
        // Add cases for other actions as needed
        default:
            return state;
    }
};

export default notificationReducer;
