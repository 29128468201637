// action - customization reducer
import {
    getAllProjects,
    getHomeRepairRequests,
    getInquiriesByService, getManualOrderRequest,
    getNotifications,
    getOrdersByService,
    getQuotationsByService, getRequestMessage,
    readNotification, sendRequestMessage, updateRepairRequest
} from "../api/api";
import { toast } from 'react-toastify';
import {useTranslation} from "react-i18next";
const current = JSON.parse(localStorage.getItem('auth_user'));
const service_id = localStorage.getItem('serv-id');

export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const getUserNotifications = (data) => {
    return async (dispatch, getState) => {
        // getState().base_url
        try {
            getNotifications(data).then((response) => {
                if (response.data.code === 1) {
                    dispatch({ type: 'SET_NOTIFICATION', payload: response.data.data.notification_arr });
                    dispatch({ type: 'SET_NOTIFICATION_COUNT', payload: response.data.data.notification_count_arr });
                    dispatch({ type: 'SET_NOTIFICATION_COUNTER', payload: response.data.data.total_count });
                    dispatch({ type: 'SET_NOTIFICATION_UNREAD_COUNT', payload: response.data.data.total_unread });
                }
            });

        } catch (error) {
            console.error(error);
        } finally {
            dispatch({ type: 'SET_HOME_LOADING', payload: false });
        }
    };
};

export const readUserNotifications = (data) => {
    return async (dispatch, getState) => {
        // getState().base_url
        try {
            readNotification(data).then((response) => {
                if (response.data.code === 1) {
                    dispatch({ type: 'SET_NOTIFICATION', payload: response.data.data });
                }
            });

        } catch (error) {
            console.error(error);
        } finally {
            dispatch({ type: 'SET_HOME_LOADING', payload: false });
        }
    };
};


export const getInquiryList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_INQUIRY_LOADING', payload: true });
            }
            if(!data.page_number){
                data.page_number = 1;
            }

            const response = await getInquiriesByService(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_INQUIRY', payload: response.data.data.request_arr });
                dispatch({ type: 'SET_TOTAL_COUNT', payload: response.data.data.total_count });
            } else {
                toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_INQUIRY_LOADING', payload: false });
            }
        }
    };
};

export const getQuotationList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_QUOTATION_LOADING', payload: true });
            }
            if(!data.page_number){
                data.page_number = 1;
            }

            const response = await getQuotationsByService(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_QUOTATION', payload: response.data.data.request_arr });
                dispatch({ type: 'SET_TOTAL_COUNT', payload: response.data.data.total_count });
            } else {
                toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_QUOTATION_LOADING', payload: false });
            }
        }
    };
};

export const getOrdersList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_ORDER_LOADING', payload: true });
            }
            if(!data.page_number){
                data.page_number = 1;
            }

            const response = await getOrdersByService(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_ORDER', payload: response.data.data.order_arr });
                dispatch({ type: 'SET_TOTAL_COUNT', payload: response.data.data.total_count });
            } else {
                toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_ORDER_LOADING', payload: false });
            }
        }
    };
};

export const getHomeRepairList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_ORDER_LOADING', payload: true });
            }

            const response = await getHomeRepairRequests(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_ORDER', payload: response.data.data.request_arr });
                dispatch({ type: 'SET_TOTAL_COUNT', payload: response.data.data.total_count });
            } else {
                toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_ORDER_LOADING', payload: false });
            }
        }
    };
};

export const updateHomeRepairList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_BUTTON_LOADING', payload: true });
            }

            const response = await updateRepairRequest(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_ORDER', payload: response.data.data });
            } else {
                toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_BUTTON_LOADING', payload: false });
            }
        }
    };
};


export const handleNotification = (notificationData, route) => {
    return (dispatch) => {
        try {
            // Directly use the notification data from Pusher
            if (notificationData.code === 1) {
                // dispatch({ type: 'SET_NOTIFICATION', payload: notificationData.data.notification_arr });
                dispatch({ type: 'SET_NOTIFICATION_COUNT', payload: notificationData.data.notification_count_arr });
                dispatch(getUserNotifications({user_id: current?._id}))
                // if(notificationData.data.inquiryCount !== inquiryCount);
                if(current && service_id && route){
                    if(route === 'ORDER'){
                        if(current.company_type === process.env.REACT_APP_USER_COMPANY){
                            dispatch(getOrdersList({ company_id: current?.company_id, service_id: service_id, status: 'ALL' }, false, null));
                        }
                        else if(current.company_type === process.env.REACT_APP_USER_INDIVIDUAL){
                            dispatch(getHomeRepairList({ technician_id: current?._id, status: 'ALL' }, false, null));
                        }
                    }
                    else if(route === 'INQUIRY'){
                        dispatch(getInquiryList({ company_id: current?.company_id, service_id: service_id }, false, null));
                    }
                    else if(route === 'QUOTATION'){
                        dispatch(getQuotationList({ user_id: current?._id, service_id: service_id }, false, null));
                    }
                }

            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({ type: 'SET_HOME_LOADING', payload: false });
        }
    };
};

export const getChatList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_CHAT_LOADING', payload: true });
            }

            const response = await getRequestMessage(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_CHAT', payload: response.data.data });
            } else {
                // toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_CHAT_LOADING', payload: false });
            }
        }
    };
};

export const getProjectList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_PROJECT_LOADING', payload: true });
            }

            const response = await getAllProjects(data);
            if (response.data.code === 1) {
                console.log(response.data.data.project_arr)
                dispatch({ type: 'SET_PROJECT', payload: response.data.data.project_arr });
                dispatch({ type: 'SET_PROJECT_COUNT', payload: response.data.data.total_count });
                dispatch({ type: 'SET_PROJECT_SPECIALITY', payload: response.data.data.speciality_arr });
            } else {
                // toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_PROJECT_LOADING', payload: false });
            }
        }
    };
};

export const sendMessage = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_CHAT_LOADING', payload: true });
            }

            const response = await sendRequestMessage(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_CHAT', payload: response.data.data });
            } else {
                // toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_CHAT_LOADING', payload: false });
            }
        }
    };
};

export const getManualOrdersList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_MANUAL_ORDER_LOADING', payload: true });
            }
            if(!data.page_number){
                data.page_number = 1;
            }

            const response = await getManualOrderRequest(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_MANUAL_ORDER', payload: response.data.data.request_arr });
                dispatch({ type: 'SET_MANUAL_TOTAL_COUNT', payload: response.data.data.total_count });
            } else {
                toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_MANUAL_ORDER_LOADING', payload: false });
            }
        }
    };
};