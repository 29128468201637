import instance from './apiConfig';
import axios from "axios";

function token() {
    if (localStorage.getItem('token')) {
        let data = localStorage.getItem('token');
        let parseData = JSON.parse(data);

        return parseData.token;
    }
    return null;
}

function headers() {
    return {
        'Content-Type': 'application/json',
        'x-access-token': token()
    };
}

export function getAppSettings(data) {
    return instance.post('/provider/setting/getProviderAppSetting', data);
}

export function acceptNewPolicy(data) {
    return instance.post('/provider/setting/acceptNewPolicy', data);
}

export function userVerification(data) {
    return instance.post('/user/uservarification', data);
}
export function resendOtp(data) {
    return instance.post('/user/resendOtp', data);
}

export function login(data) {
    return instance.post('/user/login', data);
}
export function register(data) {
    return instance.post('/user/register', data);
}

export function forgotpassword(data) {
    return instance.post('/user/forgotpassword', data);
}

export function resetPassword(data) {
    return instance.post('/user/resetPassword', data);
}

export function updateProvider(data) {
    return instance.post('/user/updateProvider', data);
}
export function getSections(data) {
    return instance.post('/provider/services/getSections', data);
}
export function saveProduct(data) {
    return instance.post('/provider/product/addProduct', data);
}
export function updateProductVariation(data) {
    return instance.post('/provider/product/updateProductVariation', data);
}
export function deleteVariationProducts(data) {
    return instance.post('/provider/product/deleteVariationProducts', data);
}
export function updateOperation(data) {
    return instance.post('/provider/product/updateOperation', data);
}
export function getallProducts(data) {
    return instance.post('/provider/product/getallProviderProducts', data);
}

export function getCompanyProductsByService(data) {
    return instance.post('/provider/product/getCompanyProductsByService', data);
}

export function exportProducts(data) {
    return instance.post('/provider/product/exportProducts', data);
}

export function importProducts(data) {
    return instance.post('/provider/product/importProducts', data);
}

export function importInventory(data) {
    return instance.post('/provider/product/importInventory', data);
}

export function getServiceById(data) {
    return instance.post('/provider/product/getServiceById', data);
}

export function getProductsByService(data) {
    return instance.post('/provider/product/getProductsByService', data);
}
export function deleteProducts(data) {
    return instance.post('/provider/product/deleteProducts', data);
}
export function updateProduct(data) {
    return instance.post('/provider/product/updateProduct', data);
}
export function getEditProduct(data) {
    return instance.post('/provider/product/getEditProduct', data);
}
export function getCategory(data = {company_id: null}) {
    return instance.post('/provider/setting/getServices', data);
}
export function getSubscribedServices(data = {company_id: null}) {
    return instance.post('/provider/services/getSubscribedServices', data);
}

export function getAllCategoriesListbyService(data) {
    return instance.post('/provider/product/getAllCategoriesListbyService', data);
}
export function getUnitByService() {
    return instance.post('/provider/product/getUnitByService');
}

export function getallUnit() {
    return instance.get('/provider/product/getallUnit');
}

export function getallProductFormData() {
    return instance.get('/provider/setting/getallProductFormData');
}

export function companyprofile(data) {
    return instance.post('/user/creartecompanyprofile', data);
}

export function getCompanyById(data) {
    return instance.post('/provider/company/getCompanyById', data);
}

export function updateCompany(data) {
    return instance.post('/provider/company/updateCompany', data);
}
export function subscribeService(data) {
    return instance.post('/user/subscribeService', data);
}
export function getPlanCart(data) {
    return instance.post('/user/getPlanCart', data);
}

export function createPlanCart(data) {
    return instance.post('/user/createPlanCart', data);
}

export function deletePlanCart(data) {
    return instance.post('/user/deleteCartPlan', data);
}

export function createbranch(data) {
    return instance.post('/provider/company/createbranch', data);
}
export function getBranches(data) {
    return instance.post('/provider/company/getBranches', data);
}
export function getBranchById(data) {
    return instance.post('/provider/company/getBranchById', data);
}

export function updatebranch(data) {
    return instance.post('/provider/company/updatebranch', data);
}

export function deleteBranch(data) {
    return instance.post('/provider/company/deleteBranch', data);
}

export function getAllProviderUsers(data) {
    return instance.post('/user/getAllProviderUsers', data);
}
export function getCompanyInquiry(data) {
    return instance.post('/provider/inquiry/getCompanyInquiry', data);
}

//inventory

export function getInventorySettings(data) {
    return instance.post('/provider/inventory/getInventorySettings', data);
}

export function saveInventorySettings(data) {
    return instance.post('/provider/inventory/saveInventorySettings', data);
}

//shipping

export function getShippingSettings(data) {
    return instance.post('/provider/shipping/getShippingSettings', data);
}

export function saveShippingSettings(data) {
    return instance.post('/provider/shipping/saveShippingSettings', data);
}

export function getShippingPackagesByService(data) {
    return instance.post('/provider/shipping/getShippingPackagesByService', data);
}

export function getShippingPackageData(data) {
    return instance.post('/provider/shipping/getShippingPackageData', data);
}

export function saveShippingPackage(data) {
    return instance.post('/provider/shipping/saveShippingPackage', data);
}

export function updateShippingPackage(data) {
    return instance.post('/provider/shipping/updateShippingPackage', data);
}

export function deleteShippingPackage(data) {
    return instance.post('/provider/shipping/deleteShippingPackage', data);
}

export function getEstimatedDelivery(data) {
    return instance.post('/provider/shipping/getEstimatedDelivery', data);
}

export function setShippingCost(data) {
    return instance.post('/provider/order/setShippingCost', data);
}

export function updateShippingCost(data) {
    return instance.post('/provider/order/updateShippingCost', data);
}

export function saveEstimatedDelivery(data) {
    return instance.post('/provider/shipping/saveEstimatedDelivery', data);
}

//inquiries

export function getInquiriesByService(data) {
    return instance.post('/provider/inquiry/getInquiriesByService', data);
}

export function getInquiryDetail(data) {
    return instance.post('/provider/inquiry/getInquiryDetail', data);
}
//quotation

export function getQuotationsByService(data) {
    return instance.post('/provider/quotation/getQuotationsByService', data);
}

export function getQuotationDetail(data) {
    return instance.post('/provider/quotation/getQuotationDetail', data);
}
//orders

export function getOrdersByService(data) {
    return instance.post('/provider/order/getOrdersByService', data);
}

export function getOrderDetail(data) {
    return instance.post('/provider/order/getOrderDetail', data);
}

export function updateOrderStatus(data) {
    return instance.post('/provider/order/updateOrderStatus', data);
}

export function getServiceByCompany(data) {
    return instance.post('/provider/services/getServiceByCompany', data);
}

export function getCompanyCategories(data) {
    return instance.post('/provider/services/getCategories', data);
}

export function createServices(data) {
    return instance.post('/provider/services/createServices', data);
}

export function getCompanyServiceById(data) {
    return instance.post('/provider/services/getCompanyServiceById', data);
}

export function updateServices(data) {
    return instance.post('/provider/services/updateServices', data);
}

export function deleteServices(data) {
    return instance.post('/provider/services/deleteServices', data);
}

export function getProjectsByCompany(data) {
    return instance.post('/provider/projects/getProjectsByCompany', data);
}

export function createProject(data) {
    return instance.post('/provider/projects/createProject', data);
}

export function getProjectById(data) {
    return instance.post('/provider/projects/getProjectById', data);
}

export function updateProject(data) {
    return instance.post('/provider/projects/updateProject', data);
}

export function deleteProject(data) {
    return instance.post('/provider/projects/deleteProject', data);
}

export function contactUs(data) {
    return instance.post('/provider/contact/contactUs', data);
}

export function getCouponByService(data) {
    return instance.post('/provider/coupon/getCouponByService', data);
}

export function deleteCouponById(data) {
    return instance.post('/provider/coupon/deleteCouponById', data);
}

export function saveCoupon(data) {
    return instance.post('/provider/coupon/saveCoupon', data);
}

export function updateCoupon(data) {
    return instance.post('/provider/coupon/updateCoupon', data);
}

export function getCouponById(data) {
    return instance.post('/provider/coupon/getCouponById', data);
}

export function getBranchesCityWise(data) {
    return instance.post('/provider/shipping/getBranchesCityWise', data);
}

export function assignAssemblyPoint(data) {
    return instance.post('/provider/shipping/assignAssemblyPoint', data);
}

//payment module
export function getPaymentMethodByService(data) {
    return instance.post('/provider/payment/getPaymentMethodByService', data);
}

export function updatePaymentMethod(data) {
    return instance.post('/provider/payment/updatePaymentMethod', data);
}

export function getBankByService(data) {
    return instance.post('/provider/payment/getBankByService', data);
}

export function addBank(data) {
    return instance.post('/provider/payment/addBank', data);
}

export function deleteBank(data) {
    return instance.post('/provider/payment/deleteBank', data);
}

export function updateBank(data) {
    return instance.post('/provider/payment/updateBank', data);
}

export function getPaymentPlanByService(data) {
    return instance.post('/provider/payment/getPaymentPlanByService', data);
}

export function addPaymentPlan(data) {
    return instance.post('/provider/payment/addPaymentPlan', data);
}

export function updatePaymentPlan(data) {
    return instance.post('/provider/payment/updatePaymentPlan', data);
}

export function deletePaymentPlan(data) {
    return instance.post('/provider/payment/deletePaymentPlan', data);
}

export function getBillInfo(data) {
    return instance.post('/provider/order/getBillInfo', data);
}

export function generateBill(data) {
    return instance.post('/provider/order/generateBill', data);
}

export function deleteBill(data) {
    return instance.post('/provider/order/deleteBill', data);
}

export function markBillPaid(data) {
    return instance.post('/provider/order/markBillPaid', data);
}

export function rejectBill(data) {
    return instance.post('/provider/order/rejectBill', data);
}

export function confirmBillOtp(data) {
    return instance.post('/provider/order/confirmBillOtp', data);
}

export function payCharge(data) {
    return instance.post('/user/pay', data);
}

export function retrievePayment(data) {
    return instance.post('/user/retrievePayment', data);
}

export function addUnit(data) {
    return instance.post('/provider/setting/createUnit', data);
}

export function updateUnit(data) {
    return instance.post('/provider/setting/updateUnit', data);
}

export function deleteUnit(data) {
    return instance.post('/provider/setting/deleteUnit', data);
}

export function getTutorialsByService(data) {
    return instance.post('/provider/services/getTutorialsByService', data);
}

export function addCustomCategory(data) {
    return instance.post('/provider/setting/createproductCategories', data);
}

export function addCustomSubCategory(data) {
    return instance.post('/provider/setting/createproductsubCategories', data);
}

export function addCustomSubSubCategory(data) {
    return instance.post('/provider/setting/createproductsubsubCategories', data);
}

export function getHomeRepairServices(data) {
    return instance.post('/provider/services/getHomeRepairServices', data);
}

export function selectHomeRepairServices(data) {
    return instance.post('/provider/services/selectHomeRepairServices', data);
}

export function getHomeRepairRequests(data) {
    return instance.post('/provider/services/getHomeRepairRequests', data);
}

export function updateRepairRequest(data) {
    return instance.post('/provider/services/updateRepairRequest', data);
}

export function getRepairRequestDetail(data) {
    return instance.post('/provider/services/getRepairRequestDetail', data);
}

export function getSelectedHomeRepairServices(data) {
    return instance.post('/provider/services/getSelectedHomeRepairServices', data);
}

export function deleteSelectedService(data) {
    return instance.post('/provider/services/deleteSelectedService', data);
}

export function getCategoryRequests(data) {
    return instance.post('/provider/services/getCategoryRequests', data);
}

export function sendNewCategoryRequest(data) {
    return instance.post('/provider/services/sendNewCategoryRequest', data);
}

export function cancelSubscription(data) {
    return instance.post('/provider/services/cancelSubscription', data);
}

export function getCancelSubscriptionRequests(data) {
    return instance.post('/provider/services/getCancelSubscriptionRequests', data);
}

export function cancelSubscriptionRequest(data) {
    return instance.post('/provider/services/cancelSubscriptionRequest', data);
}

export function getCompanyHours(data) {
    return instance.post('/provider/setting/getCompanyHours', data);
}

export function getCities() {
    return instance.get('/provider/setting/getCities');
}

export function uploadInvoice(data) {
    return instance.post('/provider/order/uploadInvoice', data);
}

export function deleteInvoice(data) {
    return instance.post('/provider/order/deleteInvoice', data);
}

export function sendProposalRepairRequest(data) {
    return instance.post('/provider/services/sendProposalRepairRequest', data);
}

export function updateProposalRepairRequest(data) {
    return instance.post('/provider/services/updateProposalRepairRequest', data);
}

export function deleteProposalRepairRequest(data) {
    return instance.post('/provider/services/deleteProposalRepairRequest', data);
}

export function getNotifications(data) {
    return instance.post('/provider/setting/getNotifications', data);
}

export function readNotification(data) {
    return instance.post('/provider/setting/readNotification', data);
}

export function getRequestMessage(data) {
    return instance.post('/provider/chat/getRequestMessage', data);
}

export function sendRequestMessage(data) {
    return instance.post('/provider/chat/sendRequestMessage', data);
}

export function getAllProjects(data) {
    return instance.post('/provider/customerProject/getAllProjects', data);
}

export function updateOrderPolicies(data) {
    return instance.post('/provider/setting/updateOrderPolicies', data);
}

export function getOrderPolicies(data) {
    return instance.post('/provider/setting/getOrderPolicies', data);
}

export function updatePaymentRequest(data) {
    return instance.post('/provider/company/updatePaymentRequest', data);
}

export function getManualOrderRequest(data) {
    return instance.post('/provider/manualOrder/getRequestList', data);
}

export function getManualOrderRequestDetail(data) {
    return instance.post('/admin/manualOrder/getRequestDetail', data);
}

export function updateManualOrderProposal(data) {
    return instance.post('/admin/manualOrder/updateManualOrderProposal', data);
}