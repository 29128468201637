import {useDispatch, useSelector} from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import NavigationScroll from 'layout/NavigationScroll';
import {getTokens, initializeFirebase, onMessageListener} from './config/firebase'
import { useGeolocated  } from "react-geolocated";
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer, toast } from 'react-toastify';
import ToastContext from './context/ToastContext';
import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getAllProviderUsers, getAppSettings} from "./api/api";
import {useTranslation} from "react-i18next";


import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import {getChatList, handleNotification, SET_FONT_FAMILY} from "./store/actions";

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import Pusher from "pusher-js";
import {disconnectPusher, initializePusher} from "./config/pusherConfig";

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer],
});


const toastOptions = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
};
// project imports
// ==============================|| APP ||============================== //

const App = () => {
    let current = JSON.parse(localStorage.getItem('auth_user'));

    // const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isTokenFound, setTokenFound] = useState(false);
    const [show, setShow] = useState(false);

    let params = useParams();
    let user_id = params.user_id;
    let company_id = params.company_id;
    let service_id = params.service_id;

    const [searchParams, setSearchParams] = useSearchParams();

    // Get a specific query parameter
    const myParam = searchParams.get('platform');

    if(!window.location.href.includes('app-') && myParam !== 'IOS'){
        // getTokens(setTokenFound);
        initializeFirebase();
        const { coords, isGeolocationAvailable, isGeolocationEnabled } =
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useGeolocated({
                positionOptions: {
                    enableHighAccuracy: false,
                },
                userDecisionTimeout: 5000,
            });

        if(isGeolocationAvailable) {

            if(isGeolocationEnabled){

                if(coords){
                    localStorage.setItem('lat', coords.latitude);
                    localStorage.setItem('lng', coords.longitude);
                }
            }
        }

        onMessageListener().then(payload=>{
            if(payload.data?.type === "PAYMENT-REQUEST"){
                toast(payload.notification.body,{
                    onClick: () => {
                        navigate(`/dashboard`)

                    },
                    timeout: 5000, // You can customize the timeout duration
                    closeOnClick: true, // Whether the toast should close when clicked
                    style: {
                        cursor: 'pointer',
                    },
                })
            }
            else{
                toast(payload.notification.body);
            }
            setShow(true)
        }).catch(err=>console.log(err))
    }



    // i18n.changeLanguage(localStorage.getItem('lang'));



    const customization = useSelector((state) => state.customization);

    getAppSettings({user_id:current?._id, platform: process.env.REACT_APP_PLATFORM})
        .then((res) => {
            const response = res.data.data;
            if(response.web_maintainace_mode){
                navigate("/maintenance");
            }
            dispatch({ type: 'SET_ACCEPT_POLICY_MODAL_OPEN', payload: response?.policy_status === process.env.REACT_APP_POLICY_FORCE_ACCEPT });

            localStorage.setItem('settings',JSON.stringify(response))

        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                toast.error(err.response?.data?.error, toastOptions);
            }
        });


    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        i18n.language==='ar'?dispatch({ type: SET_FONT_FAMILY, font: 'Alexandria'}):dispatch({ type: SET_FONT_FAMILY, font: 'Karla'});
        const user_id = JSON.parse(localStorage.getItem('auth_user'))?._id;
        if(user_id){
            const { userChannel, globalChannel,chatChannel } = initializePusher(user_id, 'CHAT');
            const routeArray  = location.pathname.split('/')
            let route;
            if(routeArray.includes('orders')){
                route = 'ORDER';
            }
            else if(routeArray.includes('inquiries')){
                route = 'INQUIRY';
            }
            else if(routeArray.includes('quotations')){
                route = 'QUOTATION';
            }
            else if(routeArray.includes('inquiry-detail')){
                route = 'INQUIRY-DETAIL';
            }
            else if(routeArray.includes('quotation-detail')){
                route = 'QUOTATION-DETAIL';
            }
            if (userChannel) {
                userChannel.bind('notification', (data) => {
                    dispatch(handleNotification(data, route));
                });
            }

            if (globalChannel) {
                globalChannel.bind('notification', (data) => {
                    dispatch(handleNotification(data, route));
                });
            }

            if (chatChannel) {
                chatChannel.bind('message', (msg) => {
                    let inPage = false;
                    let isInquiry = msg.data?.type === 'INQUIRY';
                    if((msg.data?.type === 'INQUIRY' || msg.data?.type === 'QUOTATION')){
                        if(route === 'INQUIRY-DETAIL') {
                            if(localStorage.getItem('inq-id') === msg.data?.request_id){
                                inPage = true;
                                dispatch(getChatList({
                                    customer_id: msg.data?.customer_id,
                                    provider_id: msg.data?.provider_id,
                                    inquiry_id: route==='INQUIRY-DETAIL'?msg.data?.request_id:null,
                                }, false, i18n));
                            }

                        }
                        else if(route === 'QUOTATION-DETAIL') {
                            if(localStorage.getItem('quote-id') === msg.data?.request_id){
                                inPage = true;
                                dispatch(getChatList({
                                    customer_id: msg.data?.customer_id,
                                    provider_id: msg.data?.provider_id,
                                    quotation_id: route==='QUOTATION-DETAIL'?msg.data?.request_id:null,
                                }, false, i18n));
                            }
                        }

                    }
                    if(!inPage){
                        toast.dismiss();
                        toast.success(
                            t('You have received a new message click to view'),{
                                onClick: () => {
                                    if(isInquiry){
                                        let navReplace = false;
                                        if(route === 'INQUIRY-DETAIL'){
                                            navReplace = true;
                                        }
                                        localStorage.setItem('inq-id', msg?.data?.request_id);
                                        navigate(`/inquiry-detail/new-message`, { replace: navReplace });
                                    }
                                    else{
                                        let navReplace = false;
                                        if(route === 'QUOTATION-DETAIL'){
                                            navReplace = true;
                                        }
                                        localStorage.setItem('quote-id', msg?.data?.request_id);
                                        navigate(`/quotation-detail/new-message`, { replace: navReplace });
                                    }

                                },
                                timeout: 5000, // You can customize the timeout duration
                                closeOnClick: true, // Whether the toast should close when clicked
                                style: {
                                    cursor: 'pointer',
                                },
                            }
                        )
                    }


                });
            }

            return () => {
                if (userChannel) {
                    userChannel.unbind('notification');
                }
                if (globalChannel) {
                    globalChannel.unbind('notification');
                }
                disconnectPusher();
            };
        }


    }, [dispatch]);

    return (
        <ToastContext.Provider value={toastOptions}>
            <StyledEngineProvider injectFirst>
                <CacheProvider value={i18n.language==='ar'?cacheRtl:cacheLtr}>
                    <ThemeProvider theme={themes(customization, i18n.language === 'ar')}>
                        <CssBaseline />
                        <NavigationScroll>
                            <ToastContainer />

                            <Provider store={store}>
                                <PersistGate loading={null} persistor={persistor}>
                                    <Routes />
                                </PersistGate>
                            </Provider>
                        </NavigationScroll>
                    </ThemeProvider>
                </CacheProvider>
            </StyledEngineProvider>
        </ToastContext.Provider>
    );
};

export default App;
