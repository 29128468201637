// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar, Box,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack, TextField,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import {useTranslation} from "react-i18next";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useNavigate} from "react-router-dom";

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //


// notification status options
const status = [
    {
        value: 'ALL',
        label: 'All Notifications'
    },
    {
        value: 'READ',
        label: 'Read'
    },
    {
        value: 'UNREAD',
        label: 'Unread'
    }
];

const NotificationList = ({setOpen}) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [value, setValue] = useState('');
    let navigate = useNavigate();
    const notificationArr = useSelector(state => state.notificationReducer?.notification_arr);

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: 28
    };

    const handleNotificationClick = (notification) => {
        if(notification.tag === process.env.REACT_APP_NOTIFICATION_QUOTATION){
            localStorage.setItem('quote-id', notification.quotation_id);
            localStorage.setItem('serv-id', notification.service_id);
            navigate(`/quotation-detail/${notification.title_en}`);
        }
        else if(notification.tag === process.env.REACT_APP_NOTIFICATION_INQUIRY){
            localStorage.setItem('inq-id', notification.inquiry_id);
            localStorage.setItem('serv-id', notification.service_id);
            navigate(`/inquiry-detail/${notification.title_en}`);
        }
        else if(notification.tag === process.env.REACT_APP_NOTIFICATION_ORDER2){
            localStorage.setItem('order-id', notification.order_id);
            localStorage.setItem('serv-id', notification.service_id);
            navigate(`/order-detail/${notification.title_en}`);
        }
        setOpen(false);
    }
    const [filteredNotificationArr, setFilteredNotificationArr] = useState(notificationArr);

    const handleChange = (event) => {
        const val = event?.target.value;
        if (val) {
            setValue(val);
            if(val === 'ALL'){
                setFilteredNotificationArr(notificationArr)
            }
            else if(val === 'UNREAD'){
                setFilteredNotificationArr(notificationArr.filter((e) => e.is_new));
            }
            else if(val === 'READ'){
                setFilteredNotificationArr(notificationArr.filter((e) => !e.is_new));
            }
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <PerfectScrollbar
                    style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}
                >
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ px: 2, pt: 0.25 }}>
                                <TextField
                                    id="outlined-select-currency-native"
                                    select
                                    fullWidth
                                    value={value}
                                    onChange={handleChange}
                                    SelectProps={{
                                        native: true
                                    }}
                                >
                                    {status.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {t(option.label)}
                                        </option>
                                    ))}
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                        </Grid>
                    </Grid>
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 330,
                            py: 0,
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                                maxWidth: 300
                            },
                            '& .MuiListItemSecondaryAction-root': {
                                top: 22
                            },
                            '& .MuiDivider-root': {
                                my: 0
                            },
                            '& .list-container': {
                                pl: 7
                            }
                        }}
                    >
                        {
                            filteredNotificationArr?.length > 0 ?
                            filteredNotificationArr?.map((notification) => (
                                <React.Fragment key={notification?._id}>
                                    <ListItemWrapper key={notification?._id} onClick={() => handleNotificationClick(notification)}>
                                        <ListItem alignItems="center">
                                            {/*<ListItemAvatar>*/}
                                            {/*    <Avatar alt="John Doe" src={User1} />*/}
                                            {/*</ListItemAvatar>*/}
                                            <ListItemText primary={
                                                notification?.tag === process.env.REACT_APP_NOTIFICATION_QUOTATION?
                                                    t('You have a new quotation'):
                                                    notification?.tag === process.env.REACT_APP_NOTIFICATION_INQUIRY?
                                                        t('You have a new inquiry'):
                                                        notification?.tag === process.env.REACT_APP_NOTIFICATION_HOME_REPAIR?
                                                            t('You have a new request'):
                                                            notification?.tag === process.env.REACT_APP_NOTIFICATION_ORDER2?
                                                                t('You have a new order'):
                                                                i18n.language==='ar'?notification.title_ar:notification.title_en

                                            } />
                                            <ListItemSecondaryAction>
                                                <Grid container justifyContent="flex-end">
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" display="block" gutterBottom>
                                                            {notification?.time_passed+' '+t(notification?.unit+' ago')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {/*<Grid container direction="column" className="list-container">*/}
                                            {/*<Grid item xs={12} sx={{ pb: 2 }}>*/}
                                            {/*    <Typography variant="subtitle2">{*/}
                                            {/*        i18n.language==='ar'?notification?.desc_ar:notification?.desc_en*/}
                                            {/*    }</Typography>*/}
                                            {/*</Grid>*/}
                                        {/*    <Grid item xs={12}>*/}
                                        {/*        <Grid container>*/}
                                        {/*            <Grid item>*/}
                                        {/*                <Chip label="Unread" sx={chipErrorSX} />*/}
                                        {/*            </Grid>*/}
                                        {/*            <Grid item>*/}
                                        {/*                <Chip label="New" sx={chipWarningSX} />*/}
                                        {/*            </Grid>*/}
                                        {/*        </Grid>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}
                                    </ListItemWrapper>
                                    <Divider />
                                </React.Fragment>
                            )):(
                                <Typography sx={{px:2, py:4, textAlign: 'center'}}>{t('No Notification Found')}</Typography>
                            )
                        }
                    </List>
                </PerfectScrollbar>
            </Grid>

        </>
    );
};

export default NotificationList;
