import { Route, Routes, Navigate } from 'react-router-dom';

// routes
import { StaffProtectedRoute, ClientProtectedRoute, AdminProtectedRoute } from './ProtectedRoutes';
import { lazy } from 'react';
// project imports
import Loadable from 'ui-component/Loadable';
import Main from 'layout/MainLayout';
import EditProduct from "../views/Buildingmaterial/Product/EditProduct";
import RepairRequest from "../views/Buildingmaterial/Orders/repairRequest";
import SelectedSkills from "../views/SelectedSkills";
import Test from "../views/Test";
import PrivacyPolicy from "../views/Policies/PrivacyPolicy";
import CustomerProject from "../views/CustomerProject";
import Policy from "../views/Policy";

const Login = Loadable(lazy(() => import('views/Authentication/authentication3/Login3')));
const ResetPass = Loadable(lazy(() => import('views/Authentication/authentication3/ResetPass')));
const Register = Loadable(lazy(() => import('views/Authentication/authentication3/Register3')));
const Dashboard = Loadable(lazy(() => import('views/dashboard/Default')));
const ContactUs = Loadable(lazy(() => import('views/ContactUs')));
const Buildingmaterial = Loadable(lazy(() => import('views/Buildingmaterial')));
const Construction = Loadable(lazy(() => import('views/Construction')));
const Service = Loadable(lazy(() => import('views/Construction/Service')));
const Branches = Loadable(lazy(() => import('views/Branches')));
const MyAccount = Loadable(lazy(() => import('views/MyAccount')));
const MyCompany = Loadable(lazy(() => import('views/MyCompany')));
const Users = Loadable(lazy(() => import('views/Users')));
const AddUser = Loadable(lazy(() => import('views/Users/AddUsers')));
const AddBranch = Loadable(lazy(() => import('views/Branches/AddBranch')));
const EditBranch = Loadable(lazy(() => import('views/Branches/EditBranch')));
const Coupon = Loadable(lazy(() => import('views/Coupons')));
const Payment = Loadable(lazy(() => import('views/Buildingmaterial/Payment')));
const AddService = Loadable(lazy(() => import('views/Construction/Service/AddService')));
const EditService = Loadable(lazy(() => import('views/Construction/Service/EditService')));
const Project = Loadable(lazy(() => import('views/Construction/Project')));
const AddProject = Loadable(lazy(() => import('views/Construction/Project/AddProject')));
const EditProject = Loadable(lazy(() => import('views/Construction/Project/EditProject')));
const Product = Loadable(lazy(() => import('views/Buildingmaterial/Product')));
const AddProduct = Loadable(lazy(() => import('views/Buildingmaterial/Product/AddProduct')));
const Inventory = Loadable(lazy(() => import('views/Buildingmaterial/Inventory')));
const Shipping = Loadable(lazy(() => import('views/Buildingmaterial/Shipping/indexNEW1')));
const Inquiries = Loadable(lazy(() => import('views/Buildingmaterial/Inquiries')));
const InquiryDetail = Loadable(lazy(() => import('views/Buildingmaterial/Inquiries/detail')));
const Quotation = Loadable(lazy(() => import('views/Buildingmaterial/Quotations')));
const QuotationDetail = Loadable(lazy(() => import('views/Buildingmaterial/Quotations/detail')));
const Order = Loadable(lazy(() => import('views/Buildingmaterial/Orders')));
const OrderDetail = Loadable(lazy(() => import('views/Buildingmaterial/Orders/detail')));
const Maintenance = Loadable(lazy(() => import('views/maintenance')));
const CancelSubscription = Loadable(lazy(() => import('views/CancelSubscription')));

const ShippingApp = Loadable(lazy(() => import('views/Buildingmaterial/Shipping/WebViewApp')));
const Tutorial = Loadable(lazy(() => import('views/tutorial')));
const TutorialApp = Loadable(lazy(() => import('views/tutorial/WebViewApp')));
const RequestCategory = Loadable(lazy(() => import('views/RequestCategory')));
const ManualOrder = Loadable(lazy(() => import('views/ManualOrder')));
const ManualOrderDetail = Loadable(lazy(() => import('views/ManualOrder/Detail')));
const TermsConditions = Loadable(lazy(() => import('views/Users/TermsConditions')));
// ==============================|| ROUTING RENDER ||==============================//

const Logout = () => {
    localStorage.removeItem('auth_user');
    localStorage.setItem(
        'token',
        JSON.stringify({
            token: ''
        })
    );
    localStorage.setItem(
        'auth_user_type',
        JSON.stringify({
            type: ''
        })
    );
    return <Navigate to="/login" />;
};

export default function ThemeRoutes() {

    return (
        <Routes>

            <Route exact path="/login" element={<Login />} />
            <Route exact path="/test" element={<Test />} />
            <Route exact path="/password-reset/:user_id/:token" element={<ResetPass />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/maintenance" element={<Maintenance />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-conditions" element={<TermsConditions />} />

            <Route path="/app-shipping/:service_id/:company_id/:user_id" element={<ShippingApp />} />
            <Route path="/app-tutorial/:service_id" element={<TutorialApp />} />

            <Route path="/" element={<ClientProtectedRoute />}>
                <Route exact element={<Main />}>
                    <Route exact path="/contact-us" element={<ContactUs />} />

                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="/services/:name" element={<Buildingmaterial />} />

                    <Route path="/policies" element={<Policy />} />
                    <Route path="/customer-project" element={<CustomerProject />} />
                    <Route path="/product/:name" element={<Product />} />
                    <Route path="/add-product/:name" element={<AddProduct />} />
                    <Route path="/edit-product/:name" element={<EditProduct />} />
                    <Route path="/inventory/:id" element={<Inventory />} />
                    <Route path="/shipping/:name" element={<Shipping />} />
                    <Route path="/inquiries/:id" element={<Inquiries />} />
                    <Route path="/inquiry-detail/:name" element={<InquiryDetail />} />
                    <Route path="/quotations/:id" element={<Quotation />} />
                    <Route path="/quotation-detail/:name" element={<QuotationDetail />} />
                    <Route path="/orders/:name" element={<Order />} />
                    <Route path="/order-detail/:id" element={<OrderDetail />} />
                    <Route path="/repair-request/:id" element={<RepairRequest />} />
                    <Route path="/coupons/:name" element={<Coupon />} />
                    <Route path="/payment/:name" element={<Payment />} />
                    <Route path="/tutorial/:name" element={<Tutorial />} />

                    <Route path="/manual-orders/:name" element={<ManualOrder />} />
                    <Route path="/manual-order/:request_id/detail/:name" element={<ManualOrderDetail />} />

                    {/* construction & Engi */}

                    <Route path="/construction/:id" element={<Construction />} />
                    <Route path="/company-services/:id" element={<Service />} />
                    <Route path="/add-Service/:id" element={<AddService />} />
                    <Route path="/edit-Service/:id" element={<EditService />} />
                    <Route path="/company-projects/:id" element={<Project />} />
                    <Route path="/add-project/:id" element={<AddProject />} />
                    <Route path="/edit-project/:id" element={<EditProject />} />
                    <Route path="/construction/Inventory/:id" element={<Inventory />} />

                    {/* Branches */}

                    <Route path="/branches" element={<Branches />} />
                    <Route path="/add-branch" element={<AddBranch />} />
                    <Route path="/edit-branch" element={<EditBranch />} />

                    {/* My Account */}

                    <Route path="/my-account" element={<MyAccount />} />
                    <Route path="/edit-company" element={<MyCompany />} />
                    <Route path="/selected-skills" element={<SelectedSkills />} />

                    {/* Users */}

                    <Route path="/users" element={<Users />} />
                    <Route path="/adduser" element={<AddUser />} />

                    {/* Category Request */}

                    <Route path="/request-category/:name" element={<RequestCategory />} />
                    <Route path="/cancel-request" element={<CancelSubscription />} />
                </Route>
            </Route>

            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
}
