import React, { useMemo, useEffect, useState, useContext } from 'react';
import {
    Grid,
    Typography,
    Button,
    FormHelperText,
    Divider,
    TextField,
    Link,
    Chip,
    Box,
    FormControlLabel,
    Switch,
    IconButton,
    RadioGroup,
    Radio,
    Select as MSelect,
    MenuItem,
    Select,
    Avatar,
    Fade, Backdrop, Modal
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    getCompanyInquiry,
    deleteProducts,
    getInquiriesByService,
    getServiceById,
    getOrdersByService,
    getOrderDetail,
    updateOrderStatus,
    updateBank,
    getPaymentPlanByService,
    getBillInfo,
    generateBill,
    deletePaymentPlan,
    deleteBill,
    markBillPaid,
    confirmBillOtp,
    rejectBill, getRepairRequestDetail, updateRepairRequest, updateProposalRepairRequest, deleteProposalRepairRequest, sendProposalRepairRequest
} from 'api/api';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import { getAllCategoriesListbyService, getallUnit,  } from 'api/api';
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import * as Yup from "yup";
import AWS from "aws-sdk";
import DropZone from "../../../ui-component/DropZone";
import file_thumb from "../../../assets/images/file-thumb.png";
import {Formik} from "formik";
import {LoadingButton} from "@mui/lab";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {readUserNotifications} from "../../../store/actions";
import {useDispatch} from "react-redux";

const RepairRequest = () => {
    const service_id = localStorage.getItem('serv-id');
    const repair_req_id = localStorage.getItem('repair-req-id');
    let current = JSON.parse(localStorage.getItem('auth_user'));
    const app_settings = JSON.parse(localStorage.getItem('settings'));

    let navigate = useNavigate();
    const toastOptions = useContext(ToastContext);
    const { t, i18n } = useTranslation();
    const [Service, setService] = React.useState();
    const [isLoading, setLoading] = useState(true);

    const [acceptOpen, setAcceptOpen] = useState(false);
    const [requestData, setRequestData] = useState();
    const [ButtonLoading, setButtonLoading] = useState(false);
    const [requestStatus, setRequestStatus] = useState();
    const [repairImageOpen, setRepairImageOpen] = useState();
    const [repairImageArr, setRepairImageArr] = useState();
    const [sendProposalOpen, setSendProposalOpen] = useState(false);
    const [technicianNote, setTechnicianNote] = useState();
    const [newPrice, setNewPrice] = useState();
    const [editProposalOpen, setEditProposalOpen] = useState(false);
    const [deleteProposalOpen, setDeleteProposalOpen] = useState(false);
    const [selectedProposal, setSelectedProposal] = useState();

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("false");
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const handleImage = (value) => {
        setImage(value);
        setOpen(true);
    };

    useEffect(() => {
        if(repair_req_id){
            dispatch(readUserNotifications({ user_id: current?._id, type: 'HOMEREPAIR', is_provider: true, notify_type_id: repair_req_id }));
        }
        getServiceById({ service_id: service_id }).then((res) => {
            setService(res.data.data);
        });

        const fetchOrderDetail = async () => {
            try{
                getRepairRequestDetail({ repair_req_id: repair_req_id, technician_id: current?._id, platform: process.env.REACT_APP_PLATFORM}).then((res) => {
                    const response = res.data;
                    if(response.code===1){
                        setRequestData(response.data);
                        if(response.data.request_image_arr && response.data.request_image_arr.length > 0){
                            let newImageArr = [];
                            response.data.request_image_arr.map((e) => newImageArr.push({ src: e }))
                            setRepairImageArr(newImageArr)
                        }
                        setLoading(false);
                    }
                    else{
                        toast.error(i18n.language==='ar'?response.ar_message:response.en_message);
                    }
                });
            }
            catch (err) {
                setLoading(false);
                if (err?.response?.status === 200) {
                    toast.error(err.response?.data?.en_message);
                }
            }
        }
        fetchOrderDetail();

    }, [dispatch, repair_req_id, current?._id]);



    const handleAcceptClose = () => {
        setAcceptOpen(false);
    };

    const handleRepairImgClose = () => {
        setRepairImageOpen(false);
    }

    const handleProposalClose = () => {
        setSendProposalOpen(false);
    }

    const handleEditProposalClose = () => {
        setEditProposalOpen(false);
    }
    const handleDeleteProposalClose = () => {
        setDeleteProposalOpen(false);
    }
    const handleProposalSubmit = () => {
        let price = newPrice;
        if(!setNewPrice){
            price = requestData.price_with_vat;
        }
        if(!technicianNote){
            toast.error(t('Please enter note'));
        }
        else{
            const formData= {
                request_id: requestData._id,
                technician_id: current._id,
                note: technicianNote,
                price: price,
                app_type: 'PROVIDER'
            }
            setButtonLoading(true);
            sendProposalRepairRequest(formData).then((res) => {
                const response = res.data;
                setButtonLoading(false);
                if(response.code === 1){
                    setRequestData(response.data);
                    handleProposalClose();
                    toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                }
                else{
                    toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                }
            })
                .catch((err) => {
                    setButtonLoading(false);
                    if (err?.response?.status === 401) {
                        toast.error(err.response?.data?.error, toastOptions);
                    }
                });
        }
    }
    const handleEditProposalSubmit = () => {
        let price = newPrice;
        if(!setNewPrice){
            price = requestData.price_with_vat;
        }
        if(!technicianNote){
            toast.error(t('Please enter note'));
        }
        else{
            const formData= {
                proposal_id: selectedProposal?._id,
                request_id: requestData._id,
                technician_id: current._id,
                note: technicianNote,
                price: price,
                app_type: 'PROVIDER'
            }
            setButtonLoading(true);
            updateProposalRepairRequest(formData).then((res) => {
                const response = res.data;
                setButtonLoading(false);
                if(response.code === 1){
                    setRequestData(response.data);
                    handleEditProposalClose();
                    toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                }
                else{
                    toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                }
            })
                .catch((err) => {
                    setButtonLoading(false);
                    if (err?.response?.status === 401) {
                        toast.error(err.response?.data?.error, toastOptions);
                    }
                });
        }
    }
    const handleDeleteProposalSubmit = () => {
        const formData= {
            proposal_id: selectedProposal?._id,
            request_id: requestData._id,
            technician_id: current._id,
            app_type: 'PROVIDER'
        }
        setButtonLoading(true);
        deleteProposalRepairRequest(formData).then((res) => {
            const response = res.data;
            setButtonLoading(false);
            if(response.code === 1){
                setRequestData(response.data);
                handleDeleteProposalClose();
                toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
            }
            else{
                toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
            }
        })
            .catch((err) => {
                setButtonLoading(false);
                if (err?.response?.status === 401) {
                    toast.error(err.response?.data?.error, toastOptions);
                }
            });
    }

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                        <SkeletonEarningCard />
                    ) :
                    (
                        <Card sx={{ minWidth: 250, cursor: 'pointer' }} elevation={2}>
                            <CardContent>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                            <Box>
                                                <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Request #')} {requestData.request_id}</Typography>
                                                {
                                                    Service?<Typography sx={{ color: 'gray' }}>
                                                        {i18n.language==='ar'?Service.title_ar:Service.title_en}
                                                    </Typography>:<></>
                                                }
                                            </Box>
                                            <Button size="small"
                                                    onClick={() => navigate(-1)}
                                                    sx={{
                                                        backgroundColor: '#0e3152',
                                                        color: 'white',
                                                        mt: 0.7,
                                                        '&:hover': {
                                                            backgroundColor: '#7b8ea0'
                                                        }
                                                    }}
                                            >
                                                {t('Back')}
                                            </Button>
                                        </Box>
                                    </Grid>


                                </Grid>
                            </CardContent>
                        </Card>
                    )
                }
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                        <SkeletonEarningCard />
                    ) :
                    (
                        <>
                            <Box sx={{ flexDirection: 'row-reverse',display: 'flex', mb: 1 }}>
                                {
                                    (!requestData.sent_proposal || (requestData.sent_proposal && requestData.sent_proposal.status === 'REJECTED'))
                                    && requestData.status === process.env.REACT_APP_REPAIR_WAITING ? (
                                            <Button variant="contained" color="success"
                                                    onClick={() => {
                                                        setSendProposalOpen(true);
                                                    }} size="small">
                                                {t('Send Proposal')}
                                            </Button>
                                        ):
                                        requestData.status !== process.env.REACT_APP_REPAIR_WAITING &&
                                        requestData.status !== process.env.REACT_APP_REPAIR_PROPOSAL_SUBMITTED &&
                                        requestData.status !== process.env.REACT_APP_REPAIR_COMPLETE &&
                                        requestData.status !== process.env.REACT_APP_REPAIR_CANCELLED && (
                                            <>
                                                <Button variant="contained" color="secondary"
                                                        onClick={() => {
                                                            if(requestData.status === process.env.REACT_APP_REPAIR_ASSIGNED) {
                                                                setRequestStatus(process.env.REACT_APP_REPAIR_PROGRESS);
                                                            }
                                                            else if(requestData.status === process.env.REACT_APP_REPAIR_PROGRESS){
                                                                setRequestStatus(process.env.REACT_APP_REPAIR_COMPLETE);
                                                            }
                                                            setAcceptOpen(true);
                                                        }} size="small">
                                                    {t('Update')}
                                                </Button>

                                                {/*<Button variant="contained" color="error" sx={{mr: 1}}*/}
                                                {/*        onClick={() => {*/}
                                                {/*            setRequestStatus(process.env.REACT_APP_REPAIR_CANCELLED);*/}
                                                {/*            setAcceptOpen(true);*/}
                                                {/*        }} size="small">*/}
                                                {/*    {t('Cancel')}*/}
                                                {/*</Button>*/}
                                            </>
                                        )
                                }
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Card sx={{ minWidth: 250, cursor: 'pointer' }} elevation={2}>
                                        <CardContent>
                                            <Grid container spacing={gridSpacing}>
                                                <Grid item lg={12} md={12}>
                                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('Customer info')}</Typography>
                                                    <Divider sx={{ mt  : 1, mb  : 1 }} />
                                                    <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                                                        <Typography sx={{mb: 1}}><span style={{fontWeight: 'bold'}}>{t('Image')}</span>:
                                                        </Typography>
                                                        <img
                                                            src={requestData.user?.media ? requestData.user?.media : process.env.REACT_APP_USER_IMG}
                                                            alt="image" width="50"/>

                                                    </Box>
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Name')}</span>: {requestData.user.name}</Typography>
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Phone')}</span>: {requestData.user.phone}</Typography>
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Email')}</span>: {requestData.user.email}</Typography>
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Address')}</span>:
                                                        <Link target="_blank" href={'https://www.google.com/maps/search/?api=1&query='+requestData.latitude+','+requestData.longitude}>{requestData.address}</Link>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    {
                                        requestData.sent_proposal && (
                                            <Card sx={{ minWidth: 250, cursor: 'pointer', mt: 2 }} elevation={2}>
                                                <CardContent>
                                                    <Grid container spacing={gridSpacing}>
                                                        <Grid item lg={12} md={12}>
                                                            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('Proposal Detail')}</Typography>
                                                            {
                                                                requestData.sent_proposal_arr && requestData.sent_proposal_arr.map((proposal) => {
                                                                    return (
                                                                        <>
                                                                            <Divider sx={{ mt  : 1, mb  : 1 }} />
                                                                            <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Price')}</span>: SR {proposal.price_with_vat}</Typography>
                                                                            <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Note')}</span>: {proposal.note}</Typography>
                                                                            <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Date')}</span>: {proposal.date_time}</Typography>
                                                                            <Box sx={{  display: 'inline-flex' }}>
                                                                                <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Status')}</span>:&nbsp;</Typography>
                                                                                {
                                                                                    proposal.status==='PENDING'?(
                                                                                            <Chip label={t('Pending')} style={{backgroundColor:'#e78f08', color: '#fff'}} />
                                                                                        ):
                                                                                        proposal.status==='ACCEPTED'?(
                                                                                                <Chip label={t('Accepted')} color="success" />
                                                                                            ):
                                                                                            (
                                                                                                <Chip label={t('Rejected')} color="error" />
                                                                                            )
                                                                                }
                                                                            </Box>
                                                                            {
                                                                                proposal.status === 'PENDING' && (
                                                                                    <Box sx={{display: 'flex', justifyContent:'space-between'}}>
                                                                                        <Button variant="contained" size="small" color="secondary" onClick={() => {
                                                                                            setTechnicianNote(proposal.note)
                                                                                            setNewPrice(proposal.price_with_vat)
                                                                                            setEditProposalOpen(true);
                                                                                            setSelectedProposal(proposal);
                                                                                        }}>
                                                                                            {t('Edit')}
                                                                                        </Button>
                                                                                        <Button variant="contained" size="small" color="error" onClick={() => {
                                                                                            setDeleteProposalOpen(true)
                                                                                            setSelectedProposal(proposal);
                                                                                        }}>
                                                                                            {t('Delete')}
                                                                                        </Button>
                                                                                    </Box>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={8}>
                                    <Card sx={{ minWidth: 250, cursor: 'pointer', mb: 2 }} elevation={2}>
                                        <CardContent>
                                            <Grid container spacing={gridSpacing}>
                                                <Grid item lg={12} md={12}>
                                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('Request Info')}</Typography>
                                                    <Divider sx={{ mt  : 1, mb  : 1 }} />
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Request #')}</span> {requestData.request_id}</Typography>
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Request date')}</span>: {
                                                        new Date(requestData.createdAt).toLocaleDateString(undefined, { month: 'long', day: 'numeric', year: 'numeric' })
                                                    }</Typography>
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Selected Date')}</span>: {requestData.selected_date}</Typography>
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Selected Slot')}</span>: {requestData.selected_slot}</Typography>
                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Payment Method')}</span>: {
                                                        i18n.language==='ar'?requestData.payment_method.title_ar:requestData.payment_method.title_en
                                                    }</Typography>

                                                    <Box sx={{  display: 'inline-flex' }}>
                                                        <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Request Status')}</span>:&nbsp;</Typography>
                                                        {
                                                            requestData.status===process.env.REACT_APP_REPAIR_WAITING?(
                                                                    <Chip label={t('Waiting')} style={{backgroundColor:'#e78f08', color: '#fff'}} />
                                                                ):
                                                                requestData.status===process.env.REACT_APP_REPAIR_ASSIGNED?(
                                                                        <Chip label={t('Assigned')} color="secondary" />
                                                                    ):
                                                                    requestData.status===process.env.REACT_APP_REPAIR_PROGRESS?(
                                                                            <Chip label={t('Progress')} style={{backgroundColor:'#0088cc', color: '#fff'}} />
                                                                        ):
                                                                        requestData.status===process.env.REACT_APP_REPAIR_COMPLETE?(
                                                                                <Chip label={t('Completed')} color="success" />
                                                                            ):
                                                                            requestData.status===process.env.REACT_APP_REPAIR_CANCELLED?(
                                                                                <Chip label={t('Cancelled')} color="error"/>
                                                                            ):(
                                                                                <></>
                                                                            )
                                                        }
                                                    </Box>
                                                    {
                                                        requestData.price_with_vat > 0 && (

                                                            <>
                                                                <Divider sx={{ mt  : 1, mb  : 1 }} />
                                                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                                    <Box sx={{display: 'flex'}}>
                                                                        <Typography sx={{ mt  : 1, mb  : 1, fontWeight: 'bold', color: 'green', fontSize: '18px'}}>
                                                                            {t('Price')}: &nbsp;
                                                                        </Typography>
                                                                        <Typography sx={{ mt  : 1, mb  : 1, fontWeight: 'bold', color: 'green', fontSize: '18px'}}>
                                                                            {t('SR')+' '+requestData.price_with_vat}
                                                                        </Typography>
                                                                    </Box>
                                                                    {
                                                                        requestData.price_status === 'PRICE-UPDATED' && (
                                                                            <Typography sx={{ mt  : 1, mb  : 1, fontWeight: 'bold', color: 'red', fontSize: '18px'}}>
                                                                                {t('Price Updated By Technician')}
                                                                            </Typography>
                                                                        )
                                                                    }
                                                                </Box>
                                                            </>
                                                        )
                                                    }
                                                    <Divider sx={{ mt  : 1, mb  : 1 }} />
                                                    <Box sx={{display: 'flex'}}>
                                                        <Box>
                                                            <Typography sx={{ mt  : 1, mb  : 1, mr: '6rem' }}><span style={{fontWeight: 'bold'}}>{t('Description')}</span>: {requestData.request_desc ? requestData.request_desc:'N/A'}</Typography>
                                                        </Box>
                                                        {
                                                            requestData.request_image_arr && requestData.request_image_arr.length > 0 && (
                                                                <Box>
                                                                    <Typography sx={{ mt  : 1, mb  : 1 }}><span style={{fontWeight: 'bold'}}>{t('Image')}</span>:</Typography>
                                                                    <Button variant="contained" color="secondary" size="small" onClick={() => {

                                                                        setRepairImageOpen(true);
                                                                    }}>{t('View')}</Button>
                                                                </Box>
                                                            )
                                                        }
                                                    </Box>
                                                    {
                                                        requestData.is_extra_service && (
                                                            <>
                                                                <Divider sx={{ mt  : 1, mb  : 1 }} />
                                                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                                    <Box>
                                                                        <Typography sx={{ mt  : 1, mb  : 1 }}>{t('Description')}: {requestData.extra_desc?requestData.extra_desc:'N/A'}</Typography>
                                                                        <Typography sx={{ mt  : 1, mb  : 1 }}>{t('Image')}:</Typography>
                                                                    </Box>
                                                                    {
                                                                        requestData.extra_image && (
                                                                            <>
                                                                                <Typography sx={{ mt  : 1, mb  : 1 }}>{t('Image')}:</Typography>
                                                                                <Avatar alt="Extra Image" src={requestData.extra_image}
                                                                                        sx={{ width: 80, height: 80 }}
                                                                                    onClick={(e) => handleImage(requestData.extra_image)}
                                                                                />
                                                                            </>
                                                                        )
                                                                    }
                                                                </Box>
                                                            </>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card sx={{ minWidth: 250, cursor: 'pointer', mb: 2 }} elevation={2}>
                                        <CardContent>
                                            <Grid container spacing={gridSpacing}>
                                                <Grid item lg={12} md={12}>
                                                    <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('Service')}</Typography>
                                                    <Divider sx={{ mt  : 1, mb  : 1 }} />
                                                    <Typography sx={{ mt  : 1, mb  : 1, mx: 2, }}><span style={{fontWeight: 'bold'}}>{t('Name')}</span>: {i18n.language === 'ar' ? requestData.service.name_ar:requestData.service.name_en}</Typography>
                                                    {/*{*/}
                                                    {/*    !requestData.sub_service && (*/}
                                                    {/*        <>*/}
                                                    {/*            <Typography sx={{ mt  : 1, mb  : 1 }}>{t('Price')}: {t('SR')+' '+requestData.service.price}</Typography>*/}
                                                    {/*            <Typography sx={{ mt  : 1, mb  : 1 }}>{t('Price') +' ('+t('Inc. VAT')+')'}: {t('SR')+' '+requestData.service.price_with_vat}</Typography>*/}
                                                    {/*        </>*/}
                                                    {/*    )*/}
                                                    {/*}*/}

                                                </Grid>
                                            </Grid>
                                            {
                                                requestData.sub_service && (
                                                        <Grid container spacing={gridSpacing}>
                                                            <Grid item lg={12} md={12}>
                                                                <Divider sx={{  mb  : 1 }} />
                                                                <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('Sub Service')}</Typography>
                                                                <Divider sx={{ mt  : 1, mb  : 1 }} />
                                                                <Typography sx={{ mt  : 1, mb  : 1 }}>{t('Name')}: {i18n.language==='ar'?requestData.sub_service.name_ar:requestData.sub_service.name_en}</Typography>
                                                                {/*<Typography sx={{ mt  : 1, mb  : 1 }}>{t('Price')}: {t('SR')+' '+requestData.sub_service.price}</Typography>*/}
                                                                {/*<Typography sx={{ mt  : 1, mb  : 1, fontWeight: 'bold', color: 'green', fontSize: '18px'}}>*/}
                                                                {/*    {t('Price') +' ('+t('Inc. VAT')+')'}: {t('SR')+' '+requestData.sub_service.price_with_vat}*/}
                                                                {/*</Typography>*/}
                                                            </Grid>
                                                        </Grid>
                                                )
                                            }
                                        </CardContent>
                                    </Card>

                                </Grid>
                            </Grid>
                        </>
                    )
                }
            </Grid>
            <Modal
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                        backgroundcolor: "red"
                    },
                    gridList: {
                        flexWrap: "nowrap",
                        transform: "translateZ(0)"
                    },
                }}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open} timeout={500}>
                    <img
                        src={image}
                        alt="asd" sx={{outline: "none"}}
                        style={{ maxHeight: "90%", maxWidth: "90%", zIndex: 10000 }}
                    />
                </Fade>
            </Modal>

            <Dialog
                open={sendProposalOpen}
                onClose={handleProposalClose}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle id="alert-dialog-title" variant="f4">
                    {t('Send Proposal')}
                </DialogTitle>
                <DialogContent sx={{ flex: 1 }}>
                    <Box sx={{ flex: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography>
                                    {t('Price')}
                                </Typography>
                                <TextField fullWidth type="number" onChange={(event) => {
                                    setNewPrice(event.target.value)
                                }} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography>
                                    {t('Note')}
                                </Typography>
                                <TextField fullWidth onChange={(event) => {
                                    setTechnicianNote(event.target.value)
                                }} />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProposalClose} variant="outlined" color="error" size="small">
                        {t('Close')}
                    </Button>
                    <LoadingButton onClick={handleProposalSubmit} variant="contained" color="secondary" size="small"
                                   loading={ButtonLoading}
                                   loadingPosition="center">
                        {t('Send Proposal')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {
                selectedProposal && (
                    <Dialog
                        open={deleteProposalOpen}
                        onClose={handleDeleteProposalClose}
                        fullWidth
                        maxWidth={'sm'}
                    >
                        <DialogTitle id="alert-dialog-title" variant="f4">
                            {t('Delete Proposal')}
                        </DialogTitle>
                        <DialogContent sx={{ flex: 1 }}>
                            <Box sx={{ flex: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography>
                                            {t('Are you sure you want to delete?')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteProposalClose} variant="outlined" color="error" size="small">
                                {t('Close')}
                            </Button>
                            <LoadingButton onClick={handleDeleteProposalSubmit} variant="contained" color="secondary" size="small"
                                           loading={ButtonLoading}
                                           loadingPosition="center">
                                {t('Delete Proposal')}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                )
            }
            {
                selectedProposal && (
                    <Dialog
                        open={editProposalOpen}
                        onClose={handleEditProposalClose}
                        fullWidth
                        maxWidth={'sm'}
                    >
                        <DialogTitle id="alert-dialog-title" variant="f4">
                            {t('Update Proposal')}
                        </DialogTitle>
                        <DialogContent sx={{ flex: 1 }}>
                            <Box sx={{ flex: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography>
                                            {t('Price')}
                                        </Typography>
                                        <TextField fullWidth type="number" defaultValue={selectedProposal.price_with_vat} onChange={(event) => {
                                            setNewPrice(event.target.value)
                                        }} />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography>
                                            {t('Note')}
                                        </Typography>
                                        <TextField fullWidth defaultValue={selectedProposal.note} onChange={(event) => {
                                            setTechnicianNote(event.target.value)
                                        }} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleEditProposalClose} variant="outlined" color="error" size="small">
                                {t('Close')}
                            </Button>
                            <LoadingButton onClick={handleEditProposalSubmit} variant="contained" color="secondary" size="small"
                                           loading={ButtonLoading}
                                           loadingPosition="center">
                                {t('Update Proposal')}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                )
            }

            {
                repairImageArr && (
                    <Lightbox
                        open={repairImageOpen}
                        close={handleRepairImgClose}
                        slides={repairImageArr}
                        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                    />
                )
            }
            {
                requestData && (
                    <Dialog open={acceptOpen} onClose={handleAcceptClose} fullWidth maxWidth="xs" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title" variant="f4">
                            {requestData.status === process.env.REACT_APP_REPAIR_WAITING?t('Accept Request'):t('Update')}
                        </DialogTitle>

                        <Formik initialValues={{
                            status: requestStatus,
                            price_with_vat: requestData?.price_with_vat,
                            technician_id: current?._id,
                            request_id: requestData?._id,
                            source: 'DETAIL'
                        }}
                                validationSchema={Yup.object().shape({
                                    price_with_vat: Yup.string().required(t('Price is required'))
                                })}
                                onSubmit={(values) => {
                                    setButtonLoading(true);
                                    updateRepairRequest(values).then((res) => {
                                        const response = res.data;
                                        setButtonLoading(false);
                                        if(response.code===1){
                                            toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                            setRequestData(response.data);
                                            handleAcceptClose();
                                        }
                                        else{
                                            toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                        }
                                    })
                                        .catch((err) => {
                                            setButtonLoading(false);
                                            if (err?.response?.status === 401) {
                                                toast.error(err.response?.data?.error, toastOptions);
                                            }
                                        });
                                }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (

                                <form noValidate onSubmit={handleSubmit}>
                                    <DialogContent>
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                            alignContent="center"
                                            wrap="nowrap"
                                        >
                                            <Grid item lg={12} md={12} sm={12}>
                                                {
                                                    requestStatus === process.env.REACT_APP_REPAIR_ASSIGNED ?(
                                                        <>
                                                            <Typography>{t('Price')} ({t('Inc. VAT')})</Typography>
                                                            <TextField
                                                                type="number"
                                                                variant="outlined"
                                                                placeholder={t('Price')}
                                                                fullWidth
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                name="price_with_vat"
                                                                value={values.price_with_vat}
                                                                error={Boolean(touched.price_with_vat && errors.price_with_vat)}
                                                            />
                                                            {touched.price_with_vat && errors.price_with_vat && (
                                                                <FormHelperText error id="standard-weight-helper-text-price_with_vat-login">
                                                                    {errors.price_with_vat}
                                                                </FormHelperText>
                                                            )}
                                                        </>

                                                    ):requestStatus === process.env.REACT_APP_REPAIR_CANCELLED ?(
                                                        <Typography>{t('Are you sure you want to cancel?')}</Typography>
                                                    ):(
                                                        <>
                                                            <Typography>{t('Select Status')}</Typography>
                                                            <Select
                                                                variant="outlined"
                                                                placeholder={t('Select Status')}
                                                                fullWidth
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                name="status"
                                                                value={values.status}
                                                                error={Boolean(touched.status && errors.status)}
                                                            >
                                                                <MenuItem key={0} value={process.env.REACT_APP_REPAIR_PROGRESS}>{t('Progress')}</MenuItem>
                                                                <MenuItem key={1} value={process.env.REACT_APP_REPAIR_COMPLETE}>{t('Completed')}</MenuItem>
                                                            </Select>
                                                            {touched.status && errors.status && (
                                                                <FormHelperText error id="standard-weight-helper-text-status-login">
                                                                    {errors.status}
                                                                </FormHelperText>
                                                            )}
                                                        </>
                                                    )
                                                }

                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        {
                                            requestStatus !== process.env.REACT_APP_REPAIR_CANCELLED && (
                                                <Button onClick={handleAcceptClose} variant="outlined" color="error">
                                                    {t('Cancel')}
                                                </Button>
                                            )
                                        }
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            loading={ButtonLoading}
                                            loadingPosition="center"
                                        >
                                            {requestStatus === process.env.REACT_APP_REPAIR_ASSIGNED?t('Accept'):
                                                requestStatus === process.env.REACT_APP_REPAIR_CANCELLED?t('Cancel'):t('Update')}
                                        </LoadingButton>
                                    </DialogActions>
                                </form>
                            )}
                        </Formik>
                    </Dialog>
                )
            }

        </Grid>
    );
};

export default RepairRequest;
