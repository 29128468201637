import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {Avatar, Box, Button, ButtonBase, Stack, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';

import {LoadingButton} from "@mui/lab";
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer, toast } from 'react-toastify';
// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import uk_lang from 'assets/images/uk.png';
import sa_lang from 'assets/images/ksa.png';
import i18n from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {SET_FONT_FAMILY, SET_MENU} from "../../../store/actions";
import {acceptNewPolicy, getAppSettings} from "../../../api/api";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const current = JSON.parse(localStorage.getItem('auth_user'));
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const acceptPolicyOpen = useSelector(state => state.notificationReducer?.acceptPolicyOpen);
    const [policyLoading, setPolicyLoading] = useState(false);

    const changeLanguage = value => {


        i18n.changeLanguage(value);
        if(value==='ar') {
            document.dir = 'rtl'
            document.body.setAttribute("dir", "rtl");
            document.querySelector("html").setAttribute("lang", 'ar')
            dispatch({ type: SET_FONT_FAMILY, font: 'Alexandria'});
        }
        else{
            document.dir = 'ltr'
            document.body.setAttribute("dir", "ltr");
            document.querySelector("html").setAttribute("lang", 'en')
            dispatch({ type: SET_FONT_FAMILY, font: 'Karla'});
        }
    };


    const handleAcceptPolicyClose = () => {
        setPolicyLoading(true);
        acceptNewPolicy({user_id:current?._id, platform: process.env.REACT_APP_PLATFORM})
            .then((res) => {
                const policyResponse = res.data;
                if(policyResponse?.code === 1){
                    getAppSettings({user_id:current?._id, platform: process.env.REACT_APP_PLATFORM})
                        .then((res) => {
                            setPolicyLoading(false);
                            const response = res.data.data;
                            dispatch({ type: 'SET_ACCEPT_POLICY_MODAL_OPEN', payload: response?.policy_status === process.env.REACT_APP_POLICY_FORCE_ACCEPT });

                            localStorage.setItem('settings',JSON.stringify(response))

                        })
                        .catch((err) => {
                            setPolicyLoading(false);
                            if (err?.response?.status === 401) {
                                toast.error(err.response?.data?.error,);
                            }
                        });
                    toast.success(i18n.language==='ar'?policyResponse.ar_message:policyResponse.en_message);
                }
                else{
                    setPolicyLoading(false);
                    toast.error(i18n.language==='ar'?policyResponse.ar_message:policyResponse.en_message);
                }
            })
            .catch((err) => {
                setPolicyLoading(false);
                toast.error(i18n.language==='ar'?err.response?.data?.ar_message:err.response?.data?.en_message);
            });
    }
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, textAlign:'center' }}>
                    <LogoSection />
                </Box>

                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Stack sx={{mx: 3}}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography variant="h2">{t('Hello')},</Typography>
                    <Typography component="span" variant="h2" sx={{ fontWeight: 400 }}>
                        {current?current.name:''}
                    </Typography>
                </Stack>
            </Stack>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

             {/*notification & profile*/}
            <NotificationSection />
            {/*<Button variant="outlined"  sx={{mx:1}} onClick={() => changeLanguage(i18n.language==='ar'?'en':'ar')}>*/}
            {/*    {*/}
            {/*        i18n.language==='ar'?'English':'العربية'*/}
            {/*    }*/}
            {/*</Button>*/}
            {
                i18n.language==='ar'?(
                    <Box onClick={() => changeLanguage(i18n.language==='ar'?'en':'ar')}
                         component="img"
                         sx={{
                             width: 40,
                             mx:1,
                             maxHeight: { xs: 233, md: 167 },
                             maxWidth: { xs: 350, md: 250 },
                         }}
                         src={uk_lang}
                    />
                ):(
                    <Box onClick={() => changeLanguage(i18n.language==='ar'?'en':'ar')}
                         component="img"
                         sx={{
                             width: 40,
                             mx:1,
                             maxHeight: { xs: 233, md: 167 },
                             maxWidth: { xs: 350, md: 250 },
                         }}
                         src={sa_lang}
                    />
                )
            }
            <ProfileSection />

            <Dialog
                open={acceptPolicyOpen}
                fullWidth
                maxWidth="xs"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Alert')}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        {t('We have updated our Terms and Conditions. Please review and accept.')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button sx={{mx: 1}} href="/terms-conditions" variant="outlined" color="secondary" size="small">
                        {t('Review')}
                    </Button>
                    <LoadingButton loading={policyLoading} loadingPosition="center"
                                   variant="contained" size="small" color="secondary" onClick={handleAcceptPolicyClose}>
                        {t('Accept Policy')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
